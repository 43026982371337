<template>
  <En v-if="i18n.locale.value === 'en'" />
  <Es v-if="i18n.locale.value === 'es'" />
</template>
<script setup>
import {useI18n} from 'vue-i18n';

import En from './en.vue';
import Es from './es.vue';

const i18n = useI18n();
</script>
